<template>
    <v-main class="menu">
      <div class="menu1">
        <Header/>
        <v-row id="head">
          <v-col
          cols="12"
          lg="5"
          md="12"
          sm="12"
          class="text-center d-block kinder1-left"
          >
          <img src="@/assets/images/kinder text.png" class="kinder-left-img" alt="Kinder">
          <div style="width: 100%;">
            <v-btn
              :to="'/register'"
                rounded
             class="button_promo mt-6 pa-7" text>QEYDİYYATDAN KEÇ</v-btn>
          </div>
          </v-col>
          <v-col
          cols="12"
          lg="7"
          md="12"
          sm="12"
          class="pb-0 text-center kinder1-right"
          >
          <img src="@/assets/images/kinder present.png" width="100%" alt="Kinder">
          </v-col>
        </v-row>
        <!-- <v-row>
              <v-btn
              :to="'/check'"
                rounded
             class="button_promo mx-auto mb-6" text>ÇEKİ QEYDİYYATDAN KEÇİRİN</v-btn>
          </v-row> -->
      </div>

      <div>
        <v-container>
          <div class="menus pt-10">
            <img width="100%" src="@/assets/images/hediyyeler.png" alt="Kinder">
          </div>
        </v-container>
  
        <!-- ----------------------------------------------------------------- -->
        <div class="hr" id="qalibler">
          <hr>
        </div>
          <div class="menus">
            <div class="qalib_bg">
              <h1 class="tile5">Qalİblər</h1>
            </div>
            <v-container>
              <v-row class="pt-10">

                  <Winner/>
              </v-row>
            </v-container>
          </div>


          <!-- ------------------------------------------------- -->


        <v-container>
          <div class="menus" style="min-height:600px" id="faq">
            <h1 class="tile6">sual - cavab</h1>
            <v-row class="pt-3">
              <v-container>
                <Text7/>
              </v-container>
            </v-row>
          </div>
        </v-container>



          <!-- ----------------------------------------------------------------- -->
          <!-- <div class="hr">
            <hr>
          </div>
          <div>
            <v-row>
              <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
              class="pb-0 text-center"
              >
              <div>
                <v-btn
                href="https://www.youtube.com/channel/UCChV_NR6wxXEz-Ji_J8wrcw/videos"
                rounded
                target="_block"
              class="button_promo mb-10" text>BÜTÜN TİRAJLAR</v-btn>
              </div>
              <iframe class="ifrm" src="https://www.youtube.com/embed/live_stream?channel=UCChV_NR6wxXEz-Ji_J8wrcw" frameborder="0" allowfullscreen></iframe>
              <p class="txtend my-10">ƏLAVƏ MƏLUMAT VƏ SUALLAR ÜÇÜN +994 99 820 68 20 NÖMRƏSİNƏ MÜRACİƏT EDƏ BİLƏRSİNİZ (WHATSAPP ÜZƏRİNDƏN ZƏNGLƏR PULSUZDUR). </p>
            </v-col>
            </v-row>
          </div>
          <div class="text-center mb-10">
            <img src="@/assets/images/logo 2.png" alt="Shah Gold" class="btm-img">
          </div> -->

      </div>
      <div class="footer">
        <br>
        <br>
        <br>
        <br>
            <p class="pl-5 " style="font-size: 16px;">Dəstək şöbəmiz sizə xidmət göstərəcək: office@creo.az</p>
            <p class="pl-5 " style="font-size: 12px;">&#169; "CREO" MMC Bütün hüquqlar qorunur.                           
                          <a
                            href="/mexfi"
                            target="_blank"
                            @click.stop
                            style="color:#fff;margin-left: 5px;"
                          >
                          
                          Məxfilik siyasəti
                          </a></p>
      </div>
    </v-main>
</template>
<script>
import Header from "../parts/Header.vue";
import Text7 from "../parts/Text7.vue";
import Winner from "../parts/Winner.vue"
  export default {
    data: () => ({
      items: [],
      
    }),
    components: { Header, Text7, Winner }
}
</script>
<style>
@import url('../../assets/css/custom.css');
</style>