<template>
    <v-app-bar
        elevation="24"
        color="#E14924"
        class="appbar"
    >
          <img src="@/assets/images/Group.png" alt="Shah Gold" class="logosh">
          <v-spacer></v-spacer>
        <div class="hidden-sm-and-down">
            <v-btn 
                v-for="(item, index) in items"
                :key="index"
                :to="item.to"
                
             class="header_link ml-3" text>{{ item.title}}</v-btn>

        </div>
  
        <div class="hidden-sm-and-down">

            <v-btn
                :to="'/profil'"
                 v-if="isLogin"
             class="header_link" text>
             ŞƏXSİ KABİNET</v-btn>
            <v-btn
                :to="'/check'"
                 v-if="isLogin"
             class="header_link" text>QƏBZİ QEYDİYYATDAN KEÇİR</v-btn>
            <v-btn
                v-if="isLogin"
                class="buttonh"
                @click="logOut"
            >
                Çıxış                 

            </v-btn>
        </div>
        <div class="hidden-md-and-up">
        <v-menu  offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon  
            v-bind="attrs"
            v-on="on"
            class="white--text"
            ></v-app-bar-nav-icon>
        </template>
        <v-list>
            <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :to="item.to"
            v-scroll-to="item.to"
            >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item
            :to="'/profil'"
             v-if="isLogin"
            >
            <v-list-item-title>ŞƏXSİ KABİNET</v-list-item-title>
            </v-list-item>
            <v-list-item
            :to="'/check'"
             v-if="isLogin"
            >
            <v-list-item-title>QƏBZİ QEYDİYYATDAN KEÇİR</v-list-item-title>
            </v-list-item>
            <v-list-item
            v-if="isLogin"
            @click="logOut"
            >
            <v-list-item-title>Çıxış</v-list-item-title>
            </v-list-item>
        </v-list>
        </v-menu>
        </div>
    </v-app-bar>
</template>
<script>
    import user from '../../store/user'
      export default {
        data: () => ({
            items: [
                { title: "ƏSAS SƏHİFƏ", to: "/" },
            ],
            isLogin: false
        }),
        methods: {
            logOut(){
                this.loading = true

                user.dispatch('updateLogin',{user:'', expires:-1})

                this.loading = false
                location.href='/'
                //  this.$router.push('/login')
            }
        },
        created(){
            this.isLogin = user.getters.is_login
        }
        
    }
    </script>
    <style>
        @import url('../../assets/css/custom.css');
    </style>